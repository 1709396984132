jQuery('.slider-nav').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	fade: true,
	asNavFor: '.slider-images',
	dots: true,
})
jQuery('.slider-images').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	fade: true,
	asNavFor: '.slider-nav',
	dots: false,
	centerMode: true,
	focusOnSelect: true,
	autoplay: false,
	arrows: false,
})
